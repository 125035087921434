import styled from '@emotion/styled'
import {
  faHome,
  faIdCard,
  faComments,
  faFlagCheckered,
  faRankingStar,
  faChartSimple,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import Link from 'next/link'
import React, { useCallback } from 'react'
import { logClickEvent } from '@/firebase/analytics'
import { useLocale } from '@/hooks/useLocale'
import { Users } from '@/firebase/firestore/Users'
import { colors } from '../../../styles/theme'

type Props = {
  userData: null | Users
  hasUnreadNotifications: boolean
}

export const Nav: React.FC<Props> = ({ userData, hasUnreadNotifications }) => {
  const { t } = useLocale()
  const onClickSearch = useCallback(() => {
    logClickEvent('NavSearchClick')
  }, [])
  const onClickDiscovery = useCallback(() => {
    logClickEvent('NavDiscoveryClick')
  }, [])
  const onClickProfile = useCallback(() => {
    logClickEvent('NavProfileClick')
  }, [])

  return (
    <Container>
      <ModalContainer>
        <Link
          href="/"
          style={{
            textDecoration: 'none',
          }}
        >
          <Button onClick={onClickSearch}>
            <Icon>
              <FontAwesomeIcon
                icon={faHome}
                size="2x"
                color={colors.text.primary}
              />
            </Icon>
            <ButtonText>{t.ホーム}</ButtonText>
          </Button>
        </Link>
        <Link
          href="/groupRooms/apex"
          style={{
            textDecoration: 'none',
          }}
        >
          <Button onClick={onClickDiscovery}>
            <NewBadge>New</NewBadge>
            <Icon>
              <FontAwesomeIcon
                icon={faComments}
                size="2x"
                color={colors.text.primary}
              />
            </Icon>
            <ButtonText>{t.募集掲示板}</ButtonText>
          </Button>
        </Link>
        <Link
          href="/bosyus"
          style={{
            textDecoration: 'none',
          }}
        >
          <Button>
            <BosyuIcon>
              <Image
                src="/static/images/icn_bosyu.svg"
                alt={'ゲーム友達募集'}
                width={40}
                height={40}
                unoptimized={true}
              />
            </BosyuIcon>
            <ButtonText>{t.ゲーム友達募集}</ButtonText>
          </Button>
        </Link>
        <Link
          href={'/me/profiles/create'}
          style={{
            textDecoration: 'none',
          }}
        >
          <Button>
            <Icon>
              <FontAwesomeIcon icon={faIdCard} color={colors.text.primary} />
            </Icon>
            <ButtonText>{t.自己紹介カード}</ButtonText>
            {hasUnreadNotifications && <Badge />}
          </Button>
        </Link>
        <Link
          href="/bestGames"
          style={{
            textDecoration: 'none',
          }}
        >
          <Button onClick={onClickProfile}>
            <NewBadge>注目</NewBadge>
            <MyPageIcon>
              <FontAwesomeIcon
                icon={faChartSimple}
                size="2x"
                color={colors.text.primary}
              />
            </MyPageIcon>
            <ButtonText>My神ゲー<br />2024</ButtonText>
          </Button>
        </Link>
      </ModalContainer>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 71px;
`

const ModalContainer = styled.nav`
  width: 100%;
  height: 71px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`

const Button = styled.div`
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

const NewBadge = styled.div`
  position: absolute;
  top: -8px;
  right: 0px;
  color: #fff;
  background-color: #ff4e4e;
  font-size: 10px;
  padding: 3px 8px;
  line-height: 1;
  border-radius: 5px;
`

const ButtonText = styled.div`
  margin-top: 8px;
  color: ${colors.text.primary};
  font-weight: bold;
  font-size: 6px;
  white-space: nowrap;
  text-align: center;
`

const BosyuIcon = styled.div`
  width: 40px;
  height: 40px;
`

const Icon = styled.div`
  width: 30px;
  height: 30px;
`

const MyPageIcon = styled.div`
  margin-bottom: 7px;
  width: 24px;
  height: 24px;
`

const Badge = styled.span`
  position: absolute;
  top: 0px;
  right: 12px;
  background-color: #ff4d4f;
  color: #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
